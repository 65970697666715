import axios from 'axios'
import EventBus from '@/events/eventBus'
import _ from 'lodash'

const defaultState = () => {
  return {
    currentState: 'form-list',
    currentForm: null,
    currentSubStep: 'overview',
    statesList: {
      'form-list': {
        key: 'form-list',
        completed: false,
        title: 'Form selection',
        type: 'text'
      },
      'forms': {
        key: 'forms',
        completed: false,
        title: 'Participants',
        type: 'forms-list-component',
        formsList: []
      },
      'payment': {
        key: 'Payment',
        completed: false,
        title: 'Payment',
        type: 'payment-component',
        actionsList: [
          {
            completed: false,
            title: 'Overview',
            key: 'payment-overview'
          },
          {
            completed: false,
            title: 'Payment',
            key: 'payment-payment'
          }
        ]
      }
    },
    formData: [],
    inputData: {},
    registrantDetailsWithCart: [],
    priceData: {},
    registrationException: {},
    clubException: {},
    clubDetails: {},
    errorDetails: {},
    showPreviousButton: false,
    firstFormId: null,
    parentalAuth: {}
  }
}

const state = defaultState()

const getters = {
  getCurrentState: (state) => {
    return state.currentState
  },
  getCurrentForm: (state) => {
    return state.currentForm
  },
  getStatesList: (state) => {
    return state.statesList
  },
  getFormsData: (state) => {
    return state.formData
  },
  getStateFormsList: (state) => {
    return state.statesList.forms.formsList
  },
  getPaymentActionsList: (state) => {
    return state.statesList.payment.actionsList
  },
  getCurrentSubStep: (state) => {
    return state.currentSubStep
  },
  getInputData: (state) => {
    return state.inputData
  },
  getPriceData: (state) => {
    return state.priceData
  },
  getClubDetails: (state) => {
    return state.clubDetails
  },
  getClubException: (state) => {
    return state.clubException
  },
  bypassFullForm: (state) => {
    return state.registrationException.bypass_full_form
  },
  bypassClosedEvent: (state) => {
    return state.registrationException.bypass_event_closing
  },
  bypassClosedRegistration: (state) => {
    return state.registrationException.bypass_registration_closing
  },
  bypassFullGroup: (state) => {
    return state.registrationException.bypass_full_group
  },
  bypassGroups: (state) => {
    return state.registrationException.groups
  },
  bypassForms: (state) => {
    return state.registrationException.forms
  },
  getRegistrantDetailsWithCart: (state) => {
    return state.registrantDetailsWithCart
  },
  getErrorDetails: (state) => {
    return state.errorDetails
  },
  getShowPreviousButton: (state) => {
    return state.showPreviousButton
  },
  getFirstFormId: (state) => {
    return state.firstFormId
  },
  getParentalAuth: (state) => {
    return state.parentalAuth
  }
}

const mutations = {
  storeFormData(state, data) {
    state.formData = data
  },
  addFormToList(state, data) {
    state.statesList.forms.formsList.push(data)
  },
  setCurrentState(state, data) {
    state.statesList[state.currentState].completed = true
    state.currentState = data.step
    state.currentForm = data.form || null
  },
  setCurrentSubStep(state, data) {
    state.currentSubStep = data
  },
  setSubStepCompleted(state, current) {
    let formsList = state.statesList.forms.formsList
    let currentIndex = formsList.findIndex(x => x.id === state.currentForm)

    formsList[currentIndex].data.steps[current].completed = true
    state.statesList.forms.formsList[currentIndex].data.steps[current].completed = true
  },
  setNextFormOrStep(state) {
    let formsList = state.statesList.forms.formsList
    let currentIndex = formsList.findIndex(x => x.id === state.currentForm)
    let nextIndex = currentIndex + 1
    // @todo probably send an event here?
    if (state.statesList.forms.formsList[currentIndex]) {
      state.statesList.forms.formsList[currentIndex].completed = true
    }
    if (formsList[nextIndex] && state.currentState != 'payment') {
      // If we have more forms available - switch to them
      state.currentForm = formsList[nextIndex].id
      state.currentSubStep = 'overview'
    } else if (state.currentState != 'payment') {
      // Else, we need to pay
      state.currentForm = null
      state.currentState = 'payment'
      state.currentSubStep = 'payment-overview'
      state.statesList.forms.completed = true
    } else {
      state.statesList.payment.actionsList[0].completed = true
      state.currentSubStep = 'payment-payment'
    }
  },
  setPrevFormOrStep(state) {
    let formsList = state.statesList.forms.formsList
    let currentIndex = formsList.findIndex(x => x.id === state.currentForm)
    let prevIndex = currentIndex - 1
    // @todo probably send an event here?
    if (formsList[prevIndex] && state.currentState != 'payment') {
      // If we have more forms available - switch to them
      let stepsList = state.statesList.forms.formsList[prevIndex].data.steps
      let lastStep = Object.keys(stepsList)[Object.keys(stepsList).length - 1]
      state.currentForm = formsList[prevIndex].id
      state.currentSubStep = lastStep
    }
  },
  addInputData(state, data) {
    let requiresCartReload = false
    // Honestly - we should rework this whole thing. for now it does the job but... It's not that clear what is going on :)
    let checkPricing = true
    if (data.form) {
      if (!data.inputs) {
        if (!state.inputData[data.form.id]) {
          // If we don't have a form formed - form it
          state.inputData[data.form.id] = {
            _options: {}
          }
        }
      }
      if (data.inputs) {
        // This sets global inputs from static fields
        state.inputData[data.form.id] = data.inputs
      } else if (data.clubEntry) {
        // This sets CLUB field data on the form
        requiresCartReload = true
        state.clubDetails[data.form.id] = data
        let clubDataInput = _.cloneDeep(data)
        delete clubDataInput.form
        state.inputData[data.form.id].club = clubDataInput
      } else {
        // This sets specific field on form
        state.inputData[data.form.id][data.field] = data.value
        state.inputData[data.form.id]['_options'][data.id] = data.value
        if (data.type && data.type === 'quantity' && (!data.value || data.value === 0)) {
          delete state.inputData[data.form.id]['_options'][data.id]
          delete state.priceData[data.form.id][data.id]
          checkPricing = false
        }
      }
    } else {
      // No form selected, it's general details here
      if (!state.inputData['general']) {
        state.inputData['general'] = {}
      }
      state.inputData['general'] = Object.assign(state.inputData['general'], data.inputs)
    }

    if (checkPricing) {
      if (data.price && (data.price > 0 || data.price < 0)) {
        if (data.value) {
          if (!state.priceData[data.form.id]) {
            state.priceData[data.form.id] = {}
          }
          state.priceData[data.form.id][data.id] = data.value
        } else {
          delete state.priceData[data.form.id][data.id]
        }
        requiresCartReload = true
      } else if (data.form && (state.priceData[data.form.id]) && state.priceData[data.form.id][data.id] !== data.value) {
        delete state.priceData[data.form.id][data.id]
        requiresCartReload = true
      }
    } else {
      requiresCartReload = true
    }

    if (requiresCartReload) {
      EventBus.$emit('cart/reload')
    }
  },
  setExceptionData(state, data) {
    state.registrationException = data
  },
  setClubData(state, data) {
    state.clubException = data
  },
  setRegistrantDetailsWithCart(state, data) {
    state.registrantDetailsWithCart = data
  },
  setErrorDetails(state, data) {
    state.errorDetails = data
  },
  resetStateToDefault(state) {
    Object.assign(state, defaultState())
  },
  setShowPreviousButton(state) {
    state.showPreviousButton = true
  },
  setDontShowPreviousButton(state) {
    state.showPreviousButton = false
  },
  setFirstFormId(state, id) {
    state.firstFormId = id
  },
  setParentalAuth(state, data) {
    state.parentalAuth = data
  }
}

const actions = {
  loadSelectedForms(state, data) {
    axios.post(`registration/retrieve-form-settings/${data.event_id}`, {
      cart_uuid: localStorage.getItem(`cartStorage_${data.event_id}`)
    })
        .then(function (resp) {
          state.commit('storeFormData', resp.data.data)
          state.dispatch('parseFormsIntoStatesList')
        })
  },
  parseFormsIntoStatesList(state) {
    for (let i = 0; i < state.getters.getFormsData.length; i++) {
      let row = state.getters.getFormsData[i]
      for (let j = 0; j < row.quantity; j++) {
        state.commit('addFormToList', {
          id: row.details.id + '-' + j,
          title: row.details.title,
          completed: false,
          data: _.cloneDeep(row.details)
        })
        if (i === 0 & j === 0) {
          state.dispatch('setStep', {
            step: 'forms',
            form: row.details.id + '-' + 0
          })
        }
      }
    }
  },
  setStep(state, step) {
    state.commit('setCurrentState', step)
  },
  setSubStep(state, step) {
    state.commit('setCurrentSubStep', step)
  },
  setSubStepCompleted(state, current) {
    state.commit('setSubStepCompleted', current)
  },
  moveToNextForm(state) {
    state.commit('setNextFormOrStep')
  },
  moveToPrevForm(state) {
    state.commit('setPrevFormOrStep')
  },
  saveInputData(state, data) {
    state.commit('addInputData', data)
  },
  saveExceptionResponse(state, data) {
    if (data.type === 'exception') {
      state.commit('setExceptionData', data)
    } else {
      state.commit('setClubData', data)
    }
  },
  saveRegistrantDetailsWithCart(state, data) {
    state.commit('setRegistrantDetailsWithCart', data)
  },
  saveErrorDetails(state, data) {
    state.commit('setErrorDetails', data)
  },
  resetEventState(state) {
    state.commit('resetStateToDefault')
  },
  setShowPreviousButton(state) {
    state.commit('setShowPreviousButton')
  },
  setDontShowPreviousButton(state) {
    state.commit('setDontShowPreviousButton')
  },
  setFirstFormId(state, id) {
    state.commit('setFirstFormId', id)
  },
  setParentalAuth(state, data) {
    state.commit('setParentalAuth', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
