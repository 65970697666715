import Vue from 'vue'
import axios from 'axios'
import router from './router/index'
import store from './store/index'
import VueI18n from 'vue-i18n'
import VueSweetalert2 from 'vue-sweetalert2'

import '@/assets/css/tailwind.css'

import App from './App.vue'

Vue.use(VueI18n)
Vue.use(VueSweetalert2)

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.request.use(
    config => {
      const token = window.localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      config.headers['Content-Type'] = 'application/json'
      return config
    },
    error => {
      router.push({'name': 'auth.login'})
      Promise.reject(error)
    })

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (401 === error.response.status) {
    store.dispatch('auth/terminateSession')
    router.push({'name': 'auth.login', query: {'terminatedSession': '1'}})
  } else {
    return Promise.reject(error)
  }
})


const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en'
})

import CurrencyFormatter from './components/CurrencyFormatter'

let currency = new CurrencyFormatter('fr-FR')
window.currency = currency
Vue.component('price', require('./components/Price').default)

new Vue({
  render: h => h(App),
  mounted() {
    let locale = localStorage.accept_language
    this.setLocale(locale || 'en')
  },
  data: {
    API_URL: process.env.VUE_APP_API_URL,
    loadedTranslations: [],
    currency
  },
  methods: {
    async setLocale(locale = 'en') {
      axios.defaults.headers.common['Accept-Language'] = locale
      localStorage.accept_language = locale
      this.$i18n.locale = locale

      if (this.loadedTranslations.indexOf(locale) > -1) {
        return
      }

      await axios.get(`${process.env.VUE_APP_TRANSLATIONS_URL}`).then(response => {
        this.$i18n.setLocaleMessage(locale, response.data)
        this.loadedTranslations.push(locale)
      })
    }
  },
  router,
  store,
  i18n
}).$mount('#app')