import 'axios'

const state = {
  eventDetails: {},
  groups: [],
  forms: []
}

const getters = {
  getEventDetails: (state) => {
    return state.eventDetails
  },
  getGroups: (state) => {
    return state.groups
  },
  getForms: (state) => {
    return state.forms
  },
  getClubSettings: (state) => {
    return state.eventDetails.clubSetting
  }
}

const mutations = {
  storeEventDetails (state, data) {
    state.eventDetails = data
    window.currency.setLocale(data.organisation.currency.code)
  },
  storeGroups (state, data) {
    state.groups = data
  },
  storeForms (state, data) {
    state.forms = data
  }
}

const actions = {
  setEventDetails (state, data) {
    state.commit('storeEventDetails', data)
  },
  setGroups (state, data) {
    state.commit('storeGroups', data)
  },
  setForms (state, data) {
    state.commit('storeForms', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
