export default class CurrencyFormatter {
  
  constructor(locale) {
    this.setLocale(locale)
  }
  
  
  /*
    This formatter treats numbers same as the php formatter in the backend to keep consistency
  */
  format(number) {
    let formatted = parseFloat(number).toLocaleString(this.locale, {style: "currency", currency: this.currency, minimumFractionDigits: 2})
    
    if (this.currency === 'USD') {
      return formatted.replace('$', '$ ')
    }
    
    return formatted
  }
  
  setLocale(locale) {
  
    locale = locale.replace('_', '-')
    
    let currencies = {
      'fr-FR': 'EUR',
      'fr-CA': 'CAD',
      'en-US': 'USD',
    }
  
    this.currency = currencies[locale] || null
    this.locale = this.currency ? locale : null
  
    if (!this.locale) {
      alert('Locale not found!')
    }
  
  }
  
}