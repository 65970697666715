import axios from 'axios'

const state = {
  list: []
}

const getters = {
  getList (state) {
    return state.list
  }
}

const mutations = {
  storeList (state, data) {
    state.list = data
  }
}

const actions = {
  loadList (state, data) {
    axios.get('profile/my-registrations')
      .then((resp) => {
        state.commit('storeList', resp.data)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}