<template>
    <div class="inline-block">
        <select v-model="selectedLocale" @input="changeLocaleEvent">
            <option v-for="(lang, i) in availableLocales" :key="i" :value="lang">{{ lang }}</option>
        </select>
    </div>
</template>
<script>
  export default {
    created() {

      this.$root.$on('LocaleChanger::beforeChange', before => {
        this.$nextTick(() => { //This is "nextTicked" because on route change we set before to null
          this.before = before
        })
      })

    },
    data() {
      return {
        selectedLocale: localStorage.accept_language || 'en',
        availableLocales: ['en', 'fr'],

        skipLocaleWatcher: false,

        //this is a callback or a promise, when it returns true or resolves() then we allow value change on input
        // if it returns bool false or rejects() the promise then we change input value back to the old value
        before: null,
      }
    },
    watch: {
      $route(to, from) {
        this.before = null
      }
    },
    methods: {
      //this method changes the locale on the input and in the root
      changeLocale(locale) {
        this.selectedLocale = locale
        this.$root.setLocale(locale)

        this.$root.$emit('LocaleChanger::changed', locale)
      },
      //this caches the event of changing locale on input and then evaluates if we need to really change it or do we keep the old one
      changeLocaleEvent(e) {
        let oldLocale = this.selectedLocale
        let newLocale = e.currentTarget.value

        //if before is set we check if it passes before changing
        if (this.before) {

          let before = this.before()

          //first check if it's a promise cause then we need to do things in async
          if (Promise.resolve(before) === before) {

            //keep the old locale until promise resolves
            this.selectedLocale = oldLocale

            before.then(() => {
              this.changeLocale(newLocale)
            }).catch(() => {
              this.selectedLocale = oldLocale
            })

          } else { //if its not a promise, then just check if bool true and change acordingly
            if (before) {
              this.changeLocale(newLocale)
            } else {
              this.selectedLocale = oldLocale
            }
          }

        } else { //if we don't have a before check then just swap locales

          this.changeLocale(newLocale)

        }
      },
    }
  }
</script>
<style lang="scss">

</style>