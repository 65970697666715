import Vue from 'vue'
import Router from 'vue-router'

const Hello = () => import('../views/home/HomeView')
const EventInfo = () => import('../views/events/EventInfo')
const EventRegistration = () => import('../views/events/EventRegistration')
const TokenLogin = () => import('../views/auth/TokenLogin')
const Login = () => import('../views/auth/Login')
const Logout = () => import('../views/auth/Logout')
const MyRegistrations = () => import('../views/profile/MyRegistrations')

const authGuard = function (to, from, next) {
  if (!window.sessionStorage.getItem('user')) next({name: 'auth.login'})
  else next()
}

const guestGuard = function (to, from, next) {
  if (window.sessionStorage.getItem('user')) next({name: 'homepage'})
  else next()
}

Vue.use(Router)

export default new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'homepage',
      component: Hello
    },
    {
      path: '/event/:id',
      name: 'event.info',
      component: EventInfo
    },
    {
      path: '/event/:id/registration/:type?/:token?',
      name: 'event.registration',
      component: EventRegistration
    },
    {
      path: '/profile/registrations',
      name: 'profile.registrations',
      component: MyRegistrations,
      beforeEnter: authGuard
    },
    {
      path: '/auth/logout',
      name: 'auth.logout',
      component: Logout,
      beforeEnter: authGuard
    },
    {
      path: '/auth/token/:token/:redirect?',
      name: 'auth.token-login',
      component: TokenLogin,
      beforeEnter: guestGuard
    },
    {
      path: '/auth/login',
      name: 'auth.login',
      component: Login,
      beforeEnter: guestGuard
    }
  ]
})