<template>
  <div id="app" class="align-top">
    <header style="width:100%; border-bottom: black 1px solid; height: 60px; line-height: 60px;" class="mb-4 flex">
      <div class="w-4/12">
        <h1 class="inline-block ml-4">
          <router-link :to="{name:'homepage'}">Adeorun</router-link>
        </h1>
        <locale-changer></locale-changer>
      </div>
      <div class="w-2/12"></div>
      <div class="w-6/12 text-right">
        <router-link v-show="!getUser" :to="{name: 'auth.login'}" class="mr-4">{{ $t('login') }}</router-link>
        <div class="inline-block" v-if="getUser">
          <router-link class="mr-4" :to="{name:'profile.registrations'}">My registrations</router-link>
          <a href="#" class="mr-4" @click="tokenLogin" v-if="getUser.is_admin">Organiser interface</a>
          <router-link class="mr-4" :to="{name:'auth.logout'}">{{ getUser.name }} (Logout)</router-link>
        </div>
      </div>
    </header>

    <transition>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import LocaleChanger from './views/global/LocaleChanger.vue'
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'

export default {
  name: 'app',
  components: {
    LocaleChanger
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  created () {
    this.loadUser()
  },
  methods: {
    ...mapActions('auth', ['loadUser']),
    tokenLogin: function () {
      let url = process.env.VUE_APP_ADMIN_URL + 'token-login/?token='
      axios.get('token')
          .then((resp) => {
            window.location = url + resp.data.token
          })
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
