import axios from 'axios'

const state = {
  user: null
}

const getters = {
  getUser(state) {
    return state.user
  }
}

const mutations = {
  storeUser(state, data) {
    state.user = data
  }
}

const actions = {
  loadUser(state) {
    if (localStorage.getItem('token')) {
      axios.get('user')
          .then((resp) => {
            state.commit('storeUser', resp.data.data)
            window.sessionStorage.setItem('user', JSON.stringify(resp.data.data))
          })
    }
  },
  login(state, data) {
    return axios.post('auth/login', {
      email: data.email,
      password: data.password
    })
        .then((resp) => {
          window.localStorage.setItem('token', resp.data.access_token)
          state.dispatch('loadUser')
        })
  },
  loginUserAfterRegistration(state, data) {
    return new Promise((resolve) => {
      window.localStorage.setItem('token', data.access_token)
      state.commit('storeUser', data.user)
      window.sessionStorage.setItem('user', JSON.stringify(data.user))
      resolve()
    })
  },
  logout(state) {
    return axios.post('auth/logout')
        .then((resp) => {
          state.dispatch('terminateSession')
        })
  },
  terminateSession(state) {
    window.localStorage.removeItem('token')
    window.sessionStorage.removeItem('user')
    state.commit('storeUser', null)
  },
  loginFromToken(state, data) {
    if (!window.localStorage.getItem('token')) {
      return axios.post('auth/token-login', {
        token: data.token
      })
          .then((resp) => {
            state.commit('storeUser', resp.data.user)
            window.sessionStorage.setItem('user', JSON.stringify(resp.data.user))
            window.localStorage.setItem('token', resp.data.access_token)
          })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}