import axios from 'axios'
import _ from 'lodash'

const defaultState = () => {
  return {
    cart: {
      items: []
    },
    counts: {},
    discount: {}
  }
}

const state = defaultState()

const getters = {
  getCart: (state) => {
    return state.cart
  },
  getCounts: (state) => {
    return state.counts
  },
  getDiscount: (state) => {
    return state.discount
  },
  cartHasItems: (state) => {
    return state.cart && !_.isEmpty(state.cart.items)
  }
}

const mutations = {
  storeCart(state, data) {
    state.cart = data
  },
  storeCounts(state, data) {
    state.counts = data
  },
  storeDiscount(state, data) {
    state.discount = data
  },
  resetStateToDefault(state) {
    Object.assign(state, defaultState())
  }
}

const actions = {
  loadCart(state, data) {
    axios.post(`cart/${data.event_id}`, {
      cart_uuid: localStorage.getItem(`cartStorage_${data.event_id}`),
      options: data.options,
      clubDetails: data.clubDetails,
      exception_type: data.exception_type,
      exception_token: data.exception_token
    })
        .then(function (resp) {
          state.commit('storeCart', resp.data.data.cart)
          state.commit('storeCounts', resp.data.data.counts)

          localStorage.setItem(`cartStorage_${data.event_id}`, resp.data.data.cart.uuid)
        })
  },
  applyDiscount(state, data) {
    return axios.post(`cart/discount/${data.event_id}`, {
      cart_uuid: localStorage.getItem(`cartStorage_${data.event_id}`),
      options: data.options,
      clubDetails: data.clubDetails,
      code: data.code
    })
        .then(function (resp) {
          state.commit('storeCart', resp.data.data.cart)
          state.commit('storeCounts', resp.data.data.counts)
          state.commit('storeDiscount', resp.data.data.discount)

          localStorage.setItem(`cartStorage_${data.event_id}`, resp.data.data.cart.uuid)
        })
  },
  addNewCartItem(state, data) {
    return axios.post(`cart/add-item/${data.event_id}/${data.form_id}`, {
      cart_uuid: localStorage.getItem(`cartStorage_${data.event_id}`),
      form: data.form,
      exception_type: data.exception_type,
      exception_token: data.exception_token
    })
        .then(function (resp) {
          state.commit('storeCart', resp.data.data.cart)
          state.commit('storeCounts', resp.data.data.counts)
          // @todo this removes cart re-load
          if (!localStorage.getItem(`cartStorage_${data.event_id}`)) {
            localStorage.setItem(`cartStorage_${data.event_id}`, resp.data.data.cart.uuid)
          }
        })
  },
  deleteCartItem(state, data) {
    axios.post(`cart/remove-item/${data.event_id}/${data.form_id}`, {
      cart_uuid: localStorage.getItem(`cartStorage_${data.event_id}`)
    })
        .then(function (resp) {
          state.commit('storeCart', resp.data.data.cart)
          state.commit('storeCounts', resp.data.data.counts)
        })
  },
  touchCart(state, data) {
    axios.post(`cart/touch/${data.event_id}`, {
      cart_uuid: localStorage.getItem(`cartStorage_${data.event_id}`)
    })
  },
  resetCartState(state) {
    state.commit('resetStateToDefault')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}