import Vue from 'vue'
import Vuex from 'vuex'
import event from '@/store/modules/event'
import cart from '@/store/modules/cart'
import eventRegistration from '@/store/modules/eventRegistration'
import auth from '@/store/modules/auth'
import myRegistrations from '@/store/modules/myRegistrations'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  namespaced: true,
  modules: {
    event,
    cart,
    eventRegistration,
    auth,
    myRegistrations
  },
  strict: debug
})